.loqate-field-address .hide-address {
  display: none;
}

.loqate-address div[class$=-control] {
  border-radius: 25px;
  color: #000000;
  border: 2px solid transparent;
  box-shadow: 0 0 1px rgba(61, 70, 77, 0.33);
  transition: border 0.2s, box-shadow 0.2s;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: text;
}
.loqate-address div[class$=-control] div[class$=-Input] input {
  box-shadow: none;
}
.loqate-address div[class$=-control]:focus-within {
  border-color: #5eac39;
}
.loqate-address div[class$=-control].field-error div[class$=-control] {
  border-color: #e82d2d !important;
}