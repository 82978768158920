.copyright-bar {
  background-color: #06164a;
  color: #d7d7d8;
  padding: 10px 0;
}

.copyright-content {
  display: block;
  text-align: center;
  font-size: 0.85em;
  line-height: 1.8em;
}

.copyright-content a {
  color: #d7d7d8;
  text-decoration: none;
}

.copyright-content a:hover {
  text-decoration: underline;
}
