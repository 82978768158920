.content-section-wave-curve {
  padding: 0;
}
.content-section-wave-curve .content-section-wave-curve-top,
.content-section-wave-curve .content-section-wave-curve-bottom {
  width: 100%;
  position: relative;
  height: 36px;
}
.content-section-wave-curve .content-section-wave-curve-top .svg-container,
.content-section-wave-curve .content-section-wave-curve-bottom .svg-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content-section-wave-curve .content-section-wave-curve-top .svg-container svg,
.content-section-wave-curve .content-section-wave-curve-bottom .svg-container svg {
  width: 100%;
  height: 100%;
}