.ttg-form .form-red {
  color: Red !important;
}
.ttg-form label {
  font-weight: 600;
}
.ttg-form .action-button {
  cursor: pointer;
  color: #007bff !important;
  border: none;
  background: none;
  padding: 0;
}
.ttg-form .action-button:hover {
  text-decoration: underline !important;
}
.ttg-form .geosuggest {
  position: relative;
}
.ttg-form .geosuggest .geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #50a72c;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}
.ttg-form .geosuggest .geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}
.ttg-form .geosuggest .geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.ttg-form .geosuggest .geosuggest__item:hover,
.ttg-form .geosuggest .geosuggest__item:focus {
  background: #f5f5f5;
}
.ttg-form .geosuggest .geosuggest__item--active {
  background: #50a72c;
  color: #fff;
}
.ttg-form .geosuggest .geosuggest__item--active:hover,
.ttg-form .geosuggest .geosuggest__item--active:focus {
  background: #ccc;
}
.ttg-form .geosuggest .geosuggest__item__matched-text {
  font-weight: bold;
}
.ttg-form .react-datepicker__day--keyboard-selected,
.ttg-form .react-datepicker__month-text--keyboard-selected {
  background-color: #064f7a !important;
}
.ttg-form .react-datepicker__input-container input {
  padding: 6px 12px;
  box-shadow: 0 0 1px #3d464d;
}
.ttg-form .form-check-label {
  font-weight: normal;
  margin-right: 10px;
}
.ttg-form .payment-info input {
  padding: 6px 12px;
  box-sizing: content-box;
}
.ttg-form .more-info-box {
  border-radius: 25px;
  color: #000000;
  border: 2px solid transparent;
  box-shadow: 0 0 1px rgba(61, 70, 77, 0.3294117647);
  padding: 15px;
  background-color: #daf1ff;
  margin-bottom: 15px;
}
.ttg-form .more-info-box p {
  color: #000000;
}
.ttg-form .more-info-box p:last-of-type {
  margin-bottom: 0;
}
.ttg-form p,
.ttg-form li {
  color: #000000;
}
.ttg-form .mxw-500 {
  max-width: 500px;
  margin-left: 0;
  margin-right: 0;
}
.ttg-form .mxw-400 {
  max-width: 400px;
  margin-left: 0;
  margin-right: 0;
}
.ttg-form .mxw-300 {
  max-width: 300px;
  margin-left: 0;
  margin-right: 0;
}
.ttg-form .mxw-200 {
  max-width: 200px;
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .ttg-form .mxw-500,
  .ttg-form .mxw-400,
  .ttg-form .mxw-300,
  .ttg-form .mxw-200 {
    max-width: 100% !important;
  }
}