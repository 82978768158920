.action-buttons {
  display: inline-block;
  font-size: 1.2em;
}
.action-buttons .orange {
  color: #fc7214 !important;
  margin-right: 3px;
}
.action-buttons .g-button-color {
  color: #fc7214 !important;
  background-color: transparent;
  margin-right: 3px;
}
.action-buttons .btn-secondary {
  border-color: #bbc3ca !important;
}