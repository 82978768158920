.content-section-intersecting-waves-scattered {
  padding: 0;
}
.content-section-intersecting-waves-scattered .content-section-intersecting-waves-scattered-top,
.content-section-intersecting-waves-scattered .content-section-intersecting-waves-scattered-bottom {
  width: 100%;
  position: relative;
  height: 50px;
}
.content-section-intersecting-waves-scattered .content-section-intersecting-waves-scattered-top .svg-container,
.content-section-intersecting-waves-scattered .content-section-intersecting-waves-scattered-bottom .svg-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content-section-intersecting-waves-scattered .content-section-intersecting-waves-scattered-top .svg-container svg,
.content-section-intersecting-waves-scattered .content-section-intersecting-waves-scattered-bottom .svg-container svg {
  width: 100%;
  height: 100%;
}