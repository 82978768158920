.content-section-intersecting-waves {
  padding: 0;
}
.content-section-intersecting-waves .content-section-intersecting-waves-top,
.content-section-intersecting-waves .content-section-intersecting-waves-bottom {
  width: 100%;
  position: relative;
  height: 50px;
}
.content-section-intersecting-waves .content-section-intersecting-waves-top .svg-container,
.content-section-intersecting-waves .content-section-intersecting-waves-bottom .svg-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content-section-intersecting-waves .content-section-intersecting-waves-top .svg-container svg,
.content-section-intersecting-waves .content-section-intersecting-waves-bottom .svg-container svg {
  width: 100%;
  height: 100%;
}