.datatableSearch {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  /* border-top: 2px solid rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.rt-th {
  text-align: left;
  font-weight: 700;
}

.rt-td {
}

@media only screen and (max-width: 768px) {
  .rt-table {
    flex: none !important;
  }
  .rt-thead,
  .rt-tbody {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
    box-sizing: border-box !important;
  }
  .rt-tr,
  .rt-th,
  .rt-td {
    display: block !important;
    flex: none !important;
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
  }
}
