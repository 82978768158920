.container-bg-grey {
  background-color: #f4f4f4;
}

.container-bg-grey-shadow {
  background-color: #f4f4f4;
  box-shadow: inset 0px 6px 8px -3px rgba(0, 0, 0, 0.1);
}

.container-bg-fruit {
  height: 438.5px;
  background-color: #f4f4f4;
  box-shadow: inset 0px 6px 8px -3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.container-bg-fruit .fruit-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 652px;
  height: 438.5px;
  min-width: 652px;
  max-width: none;
  transition: all 0.25s ease-in-out;
  z-index: 0;
}

.container-bg-fruit .top-shadow {
  position: absolute;
  right: 0;
  top: 0;
  width: 652px;
  height: 8px;
  min-width: 652px;
  box-shadow: inset 0px 6px 8px -3px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.container-bg-food {
  background-color: #f0f7f9;
  position: relative;
}
.container-bg-food .food-img {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: 100%;
  min-width: 448.5px;
  max-width: none;
  transition: all 0.25s ease-in-out;
  z-index: 0;
}
.container-bg-food h2,
.container-bg-food p {
  max-width: 600px;
}

.content-section h1 {
  font-family: "Open Sans";
  color: #064f7a;
  font-size: 2.5em;
}
@media screen and (max-width: 500px) {
  .content-section h1 {
    font-size: 2em;
  }
}

.content-section h2 {
  font-family: "Open Sans";
  color: #064f7a;
  font-size: 1.6em;
  line-height: 1.35em;
  margin-bottom: 20px;
}

.content-section h5 {
  margin-bottom: 15px;
}

.content-section h3,
.content-section h4,
.content-section h5,
.content-section h6 {
  font-family: "Open Sans";
  color: #064f7a;
}

.content-section p {
  color: #000000;
}

.content-section p.fsb {
  font-size: 1.25em;
}

.content-section li {
  color: #000000;
}

.content-section h1.orange-brush {
  background-image: url("../../assets/images/orange-brush.png");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 178px 7.5px;
  padding-bottom: 15px;
}
@media only screen and (max-width: 500px) {
  .content-section h1.orange-brush {
    font-size: 2em;
  }
}

@media only screen and (max-width: 1180px) {
  .container-bg-food h2,
  .container-bg-food p {
    max-width: 500px;
  }
  .container-bg-food .food-img {
    opacity: 0.6;
  }
}
@media only screen and (max-width: 990px) {
  .container-bg-food h2,
  .container-bg-food p {
    max-width: 100%;
  }
  .container-bg-food .food-img {
    opacity: 0.2;
  }
}
@media only screen and (max-width: 767px) {
  .container-bg-fruit .fruit-img {
    opacity: 0.2;
  }
}
.content-section ul.two-col {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

@media only screen and (max-width: 500px) {
  .content-section ul.two-col {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}