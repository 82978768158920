@import "../../assets/styles/variables.scss";

.owl-stage-outer {
  padding: 10px;
}

.owl-carousel .owl-stage {
  display: flex;
}

.owl-dots .owl-dot span {
  background-color: $lightGreenColor !important;
}
.owl-dots .active span {
  background-color: $greenColor !important;
}
