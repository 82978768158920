@media only screen and (max-width: 768px) {
  .new-enrolment-table {
    border-top: 1px solid #dee2e6;
  }
  .new-enrolment-table thead {
    display: none;
  }
  .new-enrolment-table td {
    display: block;
    border-top: none;
  }
  .new-enrolment-table .cost-val {
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.2em;
  }
  .new-enrolment-table .action-btns {
    text-align: left;
    border-bottom: 1px solid #dee2e6;
  }
  .new-enrolment-table .course-icon {
    margin: 0 8px 8px 0;
    font-size: 0.9em;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    background-color: #fc7214;
    border-radius: 25px;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
    color: #ffffff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  .new-enrolment-table .course-icon i {
    display: none;
  }
  .new-enrolment-table .course-icon span {
    display: inline;
  }
}