.cms-styles-common {
  font-size: 1em;
}
.cms-styles-common h1 {
  font-family: "Open Sans";
  font-size: 1.75em !important;
  font-weight: 500;
}
.cms-styles-common h2 {
  font-family: "Open Sans";
  font-size: 1.5em !important;
  font-weight: 500;
}
.cms-styles-common h3 {
  font-family: "Open Sans";
  color: #000000;
  font-size: 1.25em;
  font-weight: 500;
}

.cms-styles-rendered .image-style-block-align-left {
  display: block;
  margin-left: 0;
  margin-right: auto;
  margin: 0.9em auto;
}
.cms-styles-rendered .image-style-block-align-right {
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin: 0.9em auto;
}
.cms-styles-rendered .image-style-align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin: 0.9em auto;
}
.cms-styles-rendered .image-style-align-left {
  float: left;
  margin-right: 1.5em;
  clear: none;
  margin: 0.9em auto;
}
.cms-styles-rendered .image-style-align-right {
  float: right;
  margin-left: 1.5em;
  clear: none;
  margin: 0.9em auto;
}
.cms-styles-rendered table {
  border-collapse: collapse;
  border: 1px solid #bfbfbf;
  margin-bottom: 16px;
}
.cms-styles-rendered table tr:first-of-type {
  background-color: #f7f7f7;
}
.cms-styles-rendered table th {
  padding: 0.4em;
  background-color: #f7f7f7;
}
.cms-styles-rendered table td {
  padding: 0.4em;
  border: 1px solid #bfbfbf;
}
.cms-styles-rendered table thead + tbody tr:first-of-type {
  background-color: transparent !important;
}
.cms-styles-rendered .cms-responsive-table {
  width: 100%;
  margin-bottom: 16px;
}
.cms-styles-rendered .cms-responsive-table .cms-responsive-tr {
  padding-bottom: 10px;
}
.cms-styles-rendered .cms-responsive-table .cms-responsive-tr .cms-responsive-tbody {
  display: flex;
  border: 1px solid #f1f1f1;
}
.cms-styles-rendered .cms-responsive-table .cms-responsive-tr .cms-responsive-tbody .cms-responsive-th {
  padding: 0 0.5em;
  text-align: right;
  border-right: 1px solid #f1f1f1;
}
.cms-styles-rendered .cms-responsive-table .cms-responsive-tr .cms-responsive-tbody .cms-responsive-td {
  flex-grow: 1;
  padding: 0 0.5em;
}
.cms-styles-rendered .cms-responsive-table .cms-responsive-tr :nth-of-type(odd) .cms-responsive-tr,
.cms-styles-rendered .cms-responsive-table .cms-responsive-tr :nth-of-type(odd) .cms-responsive-th,
.cms-styles-rendered .cms-responsive-table .cms-responsive-tr :nth-of-type(odd) .cms-responsive-td {
  background-color: #f7f7f7;
}

.cms-styles-remove-margin div > :last-child {
  margin-bottom: 0 !important;
}
.cms-styles-remove-margin ul,
.cms-styles-remove-margin ol {
  margin-left: 1em;
  padding-inline-start: 0;
}
.cms-styles-remove-margin ul li,
.cms-styles-remove-margin ol li {
  position: relative;
  left: 1em;
  padding-right: 1em;
}

.veggies-bg {
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/veggies-bg.png");
  background-size: min(90vw, 1800px);
  background-position: top center;
  background-repeat: no-repeat;
}

.table-cloth {
  background-color: #fcfced;
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/home-hero-bg.jpg");
}

.off-white {
  background-color: #fffefb;
}

.alt-off-white {
  background-color: #f7f9ef;
}

.green-chef {
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/green-chef-bg.webp");
  background-position: center center;
  background-repeat: no-repeat;
}

.food-bowl {
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/food-bowl-bg.webp");
  background-position: center center;
  background-repeat: no-repeat;
}

.grey {
  background-color: #f4f4f4;
}

html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  min-height: 100vh;
  position: relative;
}

.ttg-app {
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

h1,
.heading-text {
  font-family: Coolvetica;
  letter-spacing: 0.05em;
}

.ttg-app img {
  max-width: 100%;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.flex-100 {
  flex: 1;
}

.ttg-container {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}
.ttg-container h1 {
  font-family: Coolvetica;
}

.ttg-btn,
.ttg-blue-btn,
.ttg-red-btn,
.ttg-arrow-btn,
.ttg-blue-green-btn {
  display: inline-block;
  background-color: #fc7214;
  border-radius: 25px;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.ttg-btn {
  padding: 0.375rem 0.75rem;
}

.ttg-lg-btn {
  padding: 0.75rem 1.25rem;
}

.ttg-arrow-btn {
  padding: 0.75rem 0.75rem;
}

.ttg-blue-btn {
  background-color: #064f7a;
}

.ttg-red-btn {
  background-color: #ff3548;
}

.ttg-blue-green-btn {
  background-color: #91ddcf;
}

.ttg-orange-btn {
  background-color: #fc7214;
}

.ttg-light-orange-btn {
  background-color: #ffbd3c;
}

.ttg-orange-outline-btn {
  background-color: #fff;
  border: 2px solid #fc7214;
  color: #fc7214;
}

.ttg-green-outline-btn {
  background-color: #fff;
  border: 2px solid #50a72c;
  color: #50a72c;
}

.ttg-blue-outline-btn {
  background-color: #fff;
  border: 2px solid #064f7a;
  color: #064f7a;
}

.ttg-btn:hover,
.ttg-arrow-btn:hover,
.ttg-orange-btn:hover,
.ttg-light-orange-btn:hover {
  background-color: #064f7a;
  text-decoration: none;
  color: #ffffff;
}

.ttg-blue-btn:hover {
  background-color: #fc7214;
  color: #ffffff;
}

.ttg-red-btn:hover {
  background-color: #fc7214;
  color: #ffffff;
}

.ttg-blue-green-btn:hover {
  background-color: #fc7214;
  color: #ffffff;
}

.ttg-orange-outline-btn:hover {
  background-color: #fc7214;
  color: #ffffff;
}

.ttg-green-outline-btn:hover {
  background-color: #50a72c;
  color: #ffffff;
}

.ttg-blue-outline-btn:hover {
  background-color: #064f7a;
  color: #ffffff;
}

.sml-ttg-btn {
  font-size: 0.8em;
}

.mxw-400 {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.ttg-box-shadow {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
}

.Toastify__toast--success {
  background-color: #064f7a !important;
}

.progress {
  background-color: #064f7a;
  height: 1.5rem;
}

.progress-bar {
  background-color: #fc7214;
}

.no-last-name {
  text-decoration: underline !important;
  color: #fc7214 !important;
  cursor: pointer !important;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.standards-compliant-tick {
  width: 120px;
}

.ttg-form input,
.ttg-form select,
.ttg-form textarea {
  border-radius: 25px;
  color: #000000;
  border: 2px solid transparent;
  box-shadow: 0 0 1px rgba(61, 70, 77, 0.3294117647);
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
}
.ttg-form input:focus,
.ttg-form select:focus,
.ttg-form textarea:focus {
  border-color: #50a72c;
  box-shadow: 0 0 0 transparent;
}
.ttg-form input[type=radio],
.ttg-form input[type=checkbox] {
  box-shadow: 0 0 0 transparent;
}

.course-content table {
  border: 1px solid #dee2e6;
}
.course-content table th,
.course-content table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border: 1px solid #dee2e6;
}
.course-content table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.course-content table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-bottom-width: 2px;
}
.course-content table thead td {
  border-bottom-width: 2px;
}
.course-content table tbody + tbody {
  border-top: 2px solid #dee2e6;
}