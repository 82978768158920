@import "../../../assets/styles/variables.scss";

.loading-text {
    margin: auto;
    padding: 10px;

    .spinner {
            margin-right: 5px;
        }
  }

@media only screen and (max-width: 768px) {
  .content-sync-table {
    border-top: 1px solid #dee2e6;
    
    thead {
      display: none;
    }

    td {
      display: block;
      border-top: none;
    }

    .status {
      font-weight: 600;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1.2em;
    }

    .action-btns {
      text-align: left;
      border-bottom: 1px solid #dee2e6;
    }
    
  }
}
