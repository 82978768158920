.header-home-top, .header-sticky, .header-top {
  z-index: 100;
  transition: all 0.5s ease-out;
}
.header-home-top .priority-nav, .header-sticky .priority-nav, .header-top .priority-nav {
  max-width: 1340px;
  margin: 0 auto;
}
.header-home-top .nav-logos img:first-of-type, .header-sticky .nav-logos img:first-of-type, .header-top .nav-logos img:first-of-type {
  max-height: 82px;
}
@media screen and (max-width: 576px) {
  .header-home-top .nav-logos img:first-of-type, .header-sticky .nav-logos img:first-of-type, .header-top .nav-logos img:first-of-type {
    max-height: 72px;
  }
}
.header-home-top .nav-logos img:last-of-type, .header-sticky .nav-logos img:last-of-type, .header-top .nav-logos img:last-of-type {
  max-height: 72px;
  margin-left: 20px;
}
@media screen and (max-width: 576px) {
  .header-home-top .nav-logos img:last-of-type, .header-sticky .nav-logos img:last-of-type, .header-top .nav-logos img:last-of-type {
    max-height: 65px;
  }
}
@media screen and (max-width: 360px) {
  .header-home-top .nav-logos img:last-of-type, .header-sticky .nav-logos img:last-of-type, .header-top .nav-logos img:last-of-type {
    max-height: 58px;
  }
}
.header-home-top .priority-nav, .header-sticky .priority-nav, .header-top .priority-nav {
  text-transform: uppercase;
}
.header-home-top .priority-nav .priority-nav-items-mt, .header-sticky .priority-nav .priority-nav-items-mt, .header-top .priority-nav .priority-nav-items-mt {
  margin-top: calc((82px - 1em) / 3) !important;
}
.header-home-top .priority-nav .priority-nav-item, .header-sticky .priority-nav .priority-nav-item, .header-top .priority-nav .priority-nav-item {
  font-weight: 600;
}
.header-home-top .priority-nav .priority-nav-item-active a, .header-sticky .priority-nav .priority-nav-item-active a, .header-top .priority-nav .priority-nav-item-active a {
  color: #fc7214 !important;
}
.header-home-top .priority-nav .priority-nav-menu ul li a, .header-sticky .priority-nav .priority-nav-menu ul li a, .header-top .priority-nav .priority-nav-menu ul li a {
  color: #fff;
}

.header-top {
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/home-hero-bg.jpg");
}

.header-sticky .nav-logos img:first-of-type {
  max-height: 62px;
}
.header-sticky .priority-nav .priority-nav-items-mt {
  margin-top: calc((82px - 1em - 10px) / 4) !important;
}
.header-sticky::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/home-hero-bg.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.95;
  z-index: -1;
}

.header {
  width: 100%;
  height: 156px;
  z-index: 100;
}

.header-login {
  display: flex;
  align-items: center;
}
.header-login input {
  max-width: 200px;
  min-width: 100px;
  margin-right: 8px;
  border-radius: 25px;
  border: none;
}