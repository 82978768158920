.quiz-questions {
  .question-title {
    font-family: Coolvetica; //font-weight: 700;
  }

  .question-text {
    margin: 10px 0;
    font-weight: 600;
  }
}
