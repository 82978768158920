#courseSurvey h1,
#courseSurvey h3 {
  color: #064f7a;
}
#courseSurvey table {
  background-color: rgba(255, 255, 255, 0.75);
}
#courseSurvey .table thead th {
  text-align: center !important;
  vertical-align: middle !important;
}
#courseSurvey .table td:nth-child(2),
#courseSurvey .table td:nth-child(3),
#courseSurvey .table td:nth-child(4),
#courseSurvey .table td:nth-child(5) {
  text-align: center;
  cursor: pointer;
}
#courseSurvey .form-check-input {
  position: relative;
  margin-left: 0;
}
#courseSurvey .field-label {
  font-weight: 600;
}
#courseSurvey .mobile-label {
  display: none;
}

@media only screen and (max-width: 550px) {
  #courseSurvey .table {
    border: none;
  }
  #courseSurvey .table th {
    display: none;
  }
  #courseSurvey .table tr,
  #courseSurvey .table td {
    display: block;
    border-top: none;
  }
  #courseSurvey .table tr:first-of-type td:nth-child(1) {
    border-top: 1px solid #dee2e6;
  }
  #courseSurvey .table td:nth-child(1) {
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.03);
  }
  #courseSurvey .mobile-label {
    display: inline;
    font-weight: 400;
  }
}