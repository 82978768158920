@import "../../assets/styles/variables.scss";

.header-home-top {
  z-index: 100;

  transition: all 0.5s ease-out;

  .priority-nav {
    max-width: $containerMaxWidth;
    margin: 0 auto;
  }

  .nav-logos {
    img:first-of-type {
      max-height: 82px;

      @media screen and (max-width: 576px) {
        max-height: 72px;
      }
    }
    img:last-of-type {
      max-height: 72px;
      margin-left: 20px;

      @media screen and (max-width: 576px) {
        max-height: 65px;
      }

      @media screen and (max-width: 360px) {
        max-height: 58px;
      }
    }
  }

  .priority-nav {
    text-transform: uppercase;

    .priority-nav-items-mt {
      margin-top: calc((82px - 1em) / 3) !important;
    }

    .priority-nav-item {
      font-weight: 600;
    }

    .priority-nav-item-active {
      a {
        color: $navBarActiveLinkColor !important;
      }
    }
    //.priority-nav-menu ul li a
    .priority-nav-menu {
      ul {
        li {
          a {
            color: #fff;
          }
        }
      }
    }
  }
}

.header-top {
  @extend .header-home-top;
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/home-hero-bg.jpg");
}

.header-sticky {
  @extend .header-home-top;

  .nav-logos {
    img:first-of-type {
      max-height: 62px;
    }
  }

  .priority-nav {
    .priority-nav-items-mt {
      margin-top: calc((82px - 1em - 10px) / 4) !important;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("https://solvable.azureedge.net/train-to-gain/images/home-hero-bg.jpg");
    background-size: cover;
    background-position: center;
    opacity: 0.95; // Set opacity for the image overlay
    z-index: -1; // Ensure it’s behind any other content
  }
}

.header {
  width: 100%;
  height: 156px;
  z-index: 100;
}

.header-login {
  display: flex;
  align-items: center;

  input {
    max-width: 200px;
    min-width: 100px;
    margin-right: 8px;
    border-radius: 25px;
    border: none;
  }
}

// @media only screen and (max-width: 576px) {
//   .ttg-app {
//     .header {
//       height: 160px;
//     }
//   }

//   .ttg-app-pv {
//     .header {
//       height: 123px;
//     }
//   }
// }

// @media only screen and (max-width: 400px) {
//   .ttg-app {
//     .header {
//       height: 138px;
//     }
//   }

//   .ttg-app-pv {
//     .header {
//       height: 101px;
//     }
//   }
// }

// @media only screen and (max-width: 321px) {
//   .ttg-app {
//     .header {
//       height: 123px;
//     }
//   }

//   .ttg-app-pv {
//     .header {
//       height: 86px;
//     }
//   }
// }
