@import "./../../assets/styles/variables.scss";

#courseSurvey {
  h1,
  h3 {
    color: $fseColor;
  }

  table {
    background-color: rgba(255, 255, 255, 0.75);
  }

  .table thead th {
    text-align: center !important;
    vertical-align: middle !important;
  }
  .table td:nth-child(2),
  .table td:nth-child(3),
  .table td:nth-child(4),
  .table td:nth-child(5) {
    text-align: center;
    cursor: pointer;
  }

  .form-check-input {
    position: relative;
    margin-left: 0;
  }

  .field-label {
    font-weight: 600;
  }

  .mobile-label {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  #courseSurvey {
    .table {
      border: none;
      th {
        display: none;
      }

      tr,
      td {
        display: block;
        border-top: none;
      }
      tr:first-of-type td:nth-child(1) {
        border-top: 1px solid #dee2e6;
      }
      td:nth-child(1) {
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
    .mobile-label {
      display: inline;
      font-weight: 400;
    }
  }
}
