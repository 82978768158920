@import "../../assets/styles/variables.scss";

#courseContent {
  .course-content-container {
    max-width: $containerMaxWidth;
    margin: 0 auto;
    background-color: #fff;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .course-loading-content {
    min-height: 600px;
  }
}
