@import "../../assets/styles/variables.scss";

.login-bar-wrap {
  width: 100%;
  background: $greenColor;
  padding: 0 16px;

  .login-bar {
    max-width: $containerMaxWidth;
    margin: 0 auto;
    display: flex;
    height: 54px;
    margin-top: -4px;
    justify-content: space-between;
    align-items: center;

    .login-contact {
      color: #ffffff;
      font-size: 0.9em;
      display: block;
      i {
        color: $lightOrangeColor;
      }

      a {
        color: #ffffff;
      }
    }

    .ttg-btn {
      div {
        display: flex;
        padding-bottom: 5px;
      }
    }

    .forgot-pass {
      color: #ffffff;
      margin-left: 8px;
      font-size: 0.9em;
    }

    input {
      font-size: 0.9em;
    }

    .logged-in-text {
      color: #ffffff;
    }

    .welcome-name {
      display: inline-block;
      color: #ffffff;
      margin-right: 25px;
      padding-top: 10px;
    }

    .logged-in-actions {
      color: #ffffff;

      .btn {
        font-size: 0.9em;
        margin-left: 6px;
      }
    }
  }
}
