.content-section-angle {
  padding: 0;
}
.content-section-angle .content-section-angle-top,
.content-section-angle .content-section-angle-bottom {
  width: 100%;
  position: relative;
  height: 36px;
}
.content-section-angle .content-section-angle-top .svg-container,
.content-section-angle .content-section-angle-bottom .svg-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content-section-angle .content-section-angle-top .svg-container svg,
.content-section-angle .content-section-angle-bottom .svg-container svg {
  width: 100%;
  height: 100%;
}