.veggies-bg {
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/veggies-bg.png");
  background-size: min(90vw, 1800px);
  background-position: top center;
  background-repeat: no-repeat;
}

.table-cloth {
  background-color: #fcfced;
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/home-hero-bg.jpg");
}

.off-white {
  background-color: #fffefb;
}

.alt-off-white {
  background-color: #f7f9ef;
}

.green-chef {
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/green-chef-bg.webp");
  background-position: center center;
  background-repeat: no-repeat;
}

.food-bowl {
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/food-bowl-bg.webp");
  background-position: center center;
  background-repeat: no-repeat;
}

.grey {
  background-color: #f4f4f4;
}
