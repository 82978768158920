.collapsible-panel .header-section {
  cursor: pointer;
}
.collapsible-panel h5 {
  font-weight: normal;
  font-size: 17px;
}
.collapsible-panel div#toggle {
  -webkit-transition: -webkit-transform 1s !important;
  transition: transform 0.5s !important;
}
.collapsible-panel div.toggle-icon {
  max-height: 20px;
}
.collapsible-panel div.toggle-now {
  -webkit-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}
.collapsible-panel .header-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}