.why-train-with-us {
  max-width: 1340px;
  margin: 0 auto;
  padding-bottom: 100px;
}
@media screen and (max-width: 500px) {
  .why-train-with-us {
    padding-bottom: 120px;
  }
}
.why-train-with-us h1 {
  text-align: center;
  font-family: Coolvetica !important;
}
@media screen and (max-width: 500px) {
  .why-train-with-us h1 {
    font-size: 1.75em !important;
  }
}
.why-train-with-us .small-green-tick-balls .green-tick-balls-item .green-tick-balls-item-text {
  color: #064f7a;
}