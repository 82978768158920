@import "../../assets/styles/variables.scss";

.business-training {
  max-width: $containerMaxWidth;
  margin: 0 auto;

  .heading-text {
    font-size: 2em;
    font-family: Coolvetica; //font-weight: 700;
    color: $fseColor;

    @media screen and (max-width: 500px) {
      font-size: 1.75em;
    }
  }

  h1 {
    font-family: Coolvetica; //font-weight: 700;
  }

  p {
    font-size: 1.25em;
    //color: $fseColor;
  }
}
