.cms-styles-common {
  font-size: 1em;

  h1 {
    font-family: $headingFontFamily;
    font-size: 1.75em !important;
    font-weight: 500;
  }

  h2 {
    font-family: $headingFontFamily;
    font-size: 1.5em !important;
    font-weight: 500;
  }

  h3 {
    font-family: $headingFontFamily;
    color: $paragraphColor;
    font-size: 1.25em;
    font-weight: 500;
  }
}

.cms-styles-rendered {  
  .image-style-block-align-left {
    display: block;
    margin-left: 0;
    margin-right: auto;
    margin: .9em auto;
  }

  .image-style-block-align-right {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin: .9em auto;
  }

  .image-style-align-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin: .9em auto;
  }

  .image-style-align-left {
    float: left;
    margin-right: 1.5em;
    clear: none;
    margin: .9em auto;
  }

  .image-style-align-right {
    float: right;
    margin-left: 1.5em;
    clear: none;
    margin: .9em auto;
  }

  table {
    border-collapse: collapse;
    border: 1px solid #bfbfbf;
    margin-bottom: 16px;

    tr:first-of-type {
      background-color: #f7f7f7;
    }

    th {
      padding: 0.4em;
      background-color: #f7f7f7;
    }

    td {
      padding: 0.4em;
      border: 1px solid #bfbfbf;
    }

    thead + tbody tr:first-of-type {
      background-color: transparent !important;
    }
  }

  .cms-responsive-table {
    width: 100%;
    margin-bottom: 16px;

    .cms-responsive-tr {
      padding-bottom: 10px;

      .cms-responsive-tbody {
        display: flex;
        border: 1px solid #f1f1f1;

        .cms-responsive-th {
          padding: 0 0.5em;
          text-align: right;
          border-right: 1px solid #f1f1f1;
        }

        .cms-responsive-td {
          flex-grow: 1;
          padding: 0 0.5em;
        }
      }

      :nth-of-type(odd) {
        .cms-responsive-tr,
        .cms-responsive-th,
        .cms-responsive-td {
          background-color: #f7f7f7;
        }
      }
    }
  }
}

.cms-styles-remove-margin {
  div > :last-child {
    margin-bottom: 0 !important;
  }

  ul,
  ol {
    margin-left: 1em;
    padding-inline-start: 0;

    li {
      position: relative;
      left: 1em;
      padding-right: 1em;
    }
  }
}
