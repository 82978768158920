.payment-area {
  .big-screen {
    .card-name-container {
      border: none !important;
      width: 389px;
      display: block;
      border-radius: 0.2em;
    }

    .card-name {
      padding: 6px 0;
    }
  }

  .small-screen {
    .card-name-container {
      border: none !important;
      width: 355px;
      display: block;
    }

    .card-name {
      margin: 0px 20px 22px 45px;
      padding: 6px;
    }

    .payment-container {
      border: 1px solid #bdbdbd;
      width: 330px;
      display: block;
      box-shadow: inset 0px 1px 2px #e5e5e5;
      border-radius: 0.2em;
    }
  }

  .payment-info {
    div {
      border: none !important;
      box-shadow: none !important;
      background-color: unset !important;
    }
  }

  table {
    width: 100%;
    position: relative;
    right: 7px;
    margin-bottom: 100px;
  }
}
