.content-section-arc {
  padding: 0;

  .content-section-arc-top,
  .content-section-arc-bottom {
    width: 100%;
    position: relative;
    height: 34px; // Controls the height of the arc section

    .svg-container {
      position: absolute;
      width: 100%;
      height: 100%; // Match the height of the section

      svg {
        width: 100%;
        height: 100%; // Make sure the SVG covers the entire section
      }
    }
  }
}
