.content-section-custom-svgs {
  padding: 0;
}
.content-section-custom-svgs .content-section-custom-svgs-top,
.content-section-custom-svgs .content-section-custom-svgs-bottom {
  width: 100%;
  position: relative;
  height: 36px;
}
.content-section-custom-svgs .content-section-custom-svgs-top .svg-container,
.content-section-custom-svgs .content-section-custom-svgs-bottom .svg-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content-section-custom-svgs .content-section-custom-svgs-top .svg-container svg,
.content-section-custom-svgs .content-section-custom-svgs-bottom .svg-container svg {
  width: 100%;
  height: 100%;
}