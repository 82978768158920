@import "../../assets/styles/variables.scss";

.faqs {
  max-width: $containerMaxWidth;
  margin: 0 auto;

  h1 {
    font-family: Coolvetica; //font-weight: 700;
  }

  h5 {
    color: #000;
  }

  .card-body {
    :last-child {
      margin-bottom: 0;
    }
  }
}
