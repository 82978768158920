.course-info .course-info-content {
  max-width: 1340px;
  margin: 0 auto;
}
.course-info .course-info-content h1 {
  font-family: Coolvetica;
}
.course-info .course-info-content h2 {
  font-size: 1.3em;
}
.course-info .course-info-content h3 {
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.5em;
  color: #000;
}
.course-info .course-info-content h5 {
  color: #000;
}
.course-info .course-info-content .course-price {
  font-size: 2em;
  margin: 16px 0;
  font-weight: 400;
  font-family: Coolvetica;
  color: #064f7a;
  letter-spacing: 0.05em;
}
.course-info .course-info-content .course-img {
  max-height: 470px;
  border: 1px solid #50a72c;
  box-shadow: 3px -1px 25px 0px rgba(76, 146, 48, 0.4);
  border-radius: 16px;
}
.course-info .course-info-content .card-body :last-child {
  margin-bottom: 0;
}
.course-info .infographic {
  margin-top: 25px !important;
}