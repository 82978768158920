.whats-next {
  position: relative;
  padding-left: 512px;
  max-width: 1024px;
  margin: 25px auto 0 auto;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1150px) {
  .whats-next {
    min-height: 500px;
  }
}
@media screen and (max-width: 900px) {
  .whats-next {
    padding-left: 16px;
    max-width: 90vw;
    text-align: center;
  }
}
.whats-next .heading-text {
  font-size: 2em;
  font-family: Coolvetica;
  color: #064f7a;
}
.whats-next .sub-heading-text {
  font-size: 1.15em;
  margin-bottom: 25px;
}
.whats-next .whats-next-text {
  font-size: 1.5em;
  color: #064f7a;
  margin-bottom: 25px;
}
.whats-next .whats-next-image {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1150px) {
  .whats-next .whats-next-image {
    left: 80px;
  }
}
@media screen and (max-width: 900px) {
  .whats-next .whats-next-image {
    top: auto;
    left: auto;
    position: relative;
  }
}
.whats-next .whats-next-image img {
  width: 480px;
  z-index: 1;
}
@media screen and (max-width: 1150px) {
  .whats-next .whats-next-image img {
    width: 400px;
  }
}
.whats-next .whats-next-image .whats-next-image-overlay {
  background-color: #fc7214;
  color: #fff;
  display: flex;
  width: 350px;
  margin-left: -60px;
  margin-top: -90px;
  z-index: 2;
  position: relative;
  padding: 8px 16px;
  align-items: center;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
@media screen and (max-width: 900px) {
  .whats-next .whats-next-image .whats-next-image-overlay {
    margin-left: calc(50% - 225px);
    margin-right: auto;
  }
}
@media screen and (max-width: 500px) {
  .whats-next .whats-next-image .whats-next-image-overlay {
    margin-left: calc(50% - 195px);
    margin-right: auto;
  }
}
.whats-next .whats-next-image .whats-next-image-overlay .whats-next-image-overlay-text-left {
  font-size: 2.5em;
  margin: 0 12px 0 0;
}
.whats-next .whats-next-image .whats-next-image-overlay .whats-next-image-overlay-text-right {
  font-size: 1em;
  margin: 0;
}
.whats-next .small-green-tick-balls {
  margin-bottom: 25px;
}
@media screen and (max-width: 900px) {
  .whats-next .small-green-tick-balls {
    width: min(380px, 85vw);
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
}
.whats-next .small-green-tick-balls .green-tick-balls-item .green-tick-balls-item-text {
  font-size: 1.15em;
  color: #064f7a;
  font-weight: 600;
}
.whats-next .ttg-btn {
  width: 150px;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 900px) {
  .whats-next .ttg-btn {
    margin-left: auto;
    margin-right: auto;
  }
}