.priority-nav {
  display: flex;
  align-items: stretch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1em;
  position: relative;
  padding: 8px 8px 8px calc(8px * 2);
}
.priority-nav .priority-nav-logo {
  display: block;
  margin-right: 8px;
  max-height: 82px;
  flex-shrink: 0;
}
.priority-nav .priority-nav-bar-wrap {
  flex: 1;
  overflow: hidden;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar {
  height: 100%;
  position: relative;
  white-space: nowrap;
  text-align: right;
  color: #064f7a;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items-mt {
  margin-top: calc((82px - 1em - 10px) / 4);
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items {
  display: inline-block;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-item {
  display: inline-block;
  padding: 10px;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-item a {
  line-height: 1.5em;
  text-decoration: none;
  color: #064f7a;
  cursor: pointer;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-item a:hover {
  color: #fc7214;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-item button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: content-box;
  text-decoration: none;
  color: #064f7a;
  cursor: pointer;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-item button:hover {
  color: #fc7214;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-btn {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 8px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  color: #064f7a;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-btn svg {
  vertical-align: top !important;
  margin-top: 1px;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-btn :hover {
  color: #fc7214;
}
.priority-nav .priority-nav-bar-wrap .priority-nav-bar .priority-nav-items .priority-nav-btn:only-child {
  margin-top: calc((82px - 1em - 10px) / 3);
}
.priority-nav .priority-nav-menu {
  background-color: #064f7a;
  color: #064f7a;
  display: inline-flex;
  position: absolute;
  right: 0;
  margin-top: 8px;
  z-index: 1000;
  padding: 0 0 calc(8px * 2) 0;
}
.priority-nav .priority-nav-menu ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-inline-start: 0;
  padding-left: 0;
  list-style-type: none;
}
.priority-nav .priority-nav-menu ul li {
  text-align: left;
  padding: 8px calc(8px * 4);
}
.priority-nav .priority-nav-menu ul li a {
  text-decoration: none;
  color: #064f7a;
  cursor: pointer;
}
.priority-nav .priority-nav-menu ul li a:hover {
  color: #fc7214;
}
.priority-nav .priority-nav-menu ul li button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: content-box;
  text-decoration: none;
  color: #064f7a;
  cursor: pointer;
}
.priority-nav .priority-nav-menu ul li button:hover {
  color: #fc7214;
}

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}