@import "../../assets/styles/variables.scss";

footer {
  background: #014b6f;
  color: #ffffff;
  max-width: 100%;
  font-size: 0.9em;

  .row {
    max-width: $containerMaxWidth;
    margin-left: auto;
    margin-right: auto;
    padding-top: 32px;
    padding-bottom: 20px;

    img {
      width: 320px;
      margin-bottom: 16px;
    }

    .footer-heading {
      color: $lightOrangeColor;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.2em;
      margin-bottom: 12px;
    }

    ul {
      padding-inline-start: 0;

      li {
        list-style-type: none;
        margin-bottom: 12px;

        a {
          color: #ffffff;
          text-transform: uppercase;

          span {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

// .footer-heading {
//   font-weight: 600;
//   display: block;
//   margin-top: 15px;
//   margin-bottom: 8px;
//   font-size: 1.05em;
// }
// .footer-content {
//   font-size: 0.9em;
//   line-height: 2em;
//   display: block;
// }

// .footer a {
//   color: #ffffff;
//   text-decoration: none;
// }

// .footer a:hover {
//   text-decoration: underline;
// }

// .footer-links {
//   columns: 4;
//   -webkit-columns: 4;
//   -moz-columns: 4;
//   font-size: 0.9em;
//   line-height: 2em;
//   padding-inline-start: 20px;
// }

// .footer .social-icon {
//   display: inline-block;
//   font-size: 2em;
// }

// @media only screen and (max-width: 767px) {
//   .footer-links {
//     columns: 3;
//     -webkit-columns: 3;
//     -moz-columns: 3;
//   }
// }

// @media only screen and (max-width: 500px) {
//   .footer-links {
//     columns: 2;
//     -webkit-columns: 2;
//     -moz-columns: 2;
//   }
// }
