@import "../../assets/styles/variables.scss";

.green-tick-balls {
  display: flex;
  flex-direction: column;

  .green-tick-balls-item {
    display: flex;
    align-items: center;
    margin: 8px 0;

    .green-tick-balls-item-icon {
      background-color: $greenColor;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;

      img {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }
    }
  }
}

.small-green-tick-balls {
  @extend .green-tick-balls;

  .green-tick-balls-item {
    .green-tick-balls-item-icon {
      width: 30px;
      height: 30px;

      img {
        width: 30px;
        min-width: 30px;
        height: 30px;
      }
    }
  }
}
