.content-section-arc {
  padding: 0;
}
.content-section-arc .content-section-arc-top,
.content-section-arc .content-section-arc-bottom {
  width: 100%;
  position: relative;
  height: 34px;
}
.content-section-arc .content-section-arc-top .svg-container,
.content-section-arc .content-section-arc-bottom .svg-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content-section-arc .content-section-arc-top .svg-container svg,
.content-section-arc .content-section-arc-bottom .svg-container svg {
  width: 100%;
  height: 100%;
}