@import "../../assets/styles/variables.scss";

.select-a-course {
  max-width: $containerMaxWidth;
  margin: 0 auto;

  h1 {
    text-align: center;
    font-family: Coolvetica !important; //font-weight: 700;
  }

  .help-question-text {
    font-size: 1.25em;
    font-weight: 600;
    color: $fseColor;
    margin: 16px 0;
  }

  .course-card {
    border-radius: 16px;
    height: 100%;
    transition: all 0.2s ease-in-out;
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;

    &:hover {
      border: 1px solid $greenColor;
      box-shadow: 3px -1px 25px 0px rgba(76, 146, 48, 0.4);
    }

    img {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .card-title {
      font-size: 1.25em;
      font-weight: 600;
      color: $fseColor;
      text-align: left;
    }

    .card-text {
      font-size: 1.1em;
      color: $fseColor;
      text-align: left;
    }

    .card-footer {
      background-color: unset !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .course-cost {
        color: $fseColor;
        font-size: 1.75em;
        font-weight: 400;
        font-family: Coolvetica;
        letter-spacing: 0.05em;
      }
    }
  }
}
