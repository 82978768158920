.faqs {
  max-width: 1340px;
  margin: 0 auto;
}
.faqs h1 {
  font-family: Coolvetica;
}
.faqs h5 {
  color: #000;
}
.faqs .card-body :last-child {
  margin-bottom: 0;
}