.login-bar-wrap {
  width: 100%;
  background: #50a72c;
  padding: 0 16px;
}
.login-bar-wrap .login-bar {
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  height: 54px;
  margin-top: -4px;
  justify-content: space-between;
  align-items: center;
}
.login-bar-wrap .login-bar .login-contact {
  color: #ffffff;
  font-size: 0.9em;
  display: block;
}
.login-bar-wrap .login-bar .login-contact i {
  color: #ffbd3c;
}
.login-bar-wrap .login-bar .login-contact a {
  color: #ffffff;
}
.login-bar-wrap .login-bar .ttg-btn div {
  display: flex;
  padding-bottom: 5px;
}
.login-bar-wrap .login-bar .forgot-pass {
  color: #ffffff;
  margin-left: 8px;
  font-size: 0.9em;
}
.login-bar-wrap .login-bar input {
  font-size: 0.9em;
}
.login-bar-wrap .login-bar .logged-in-text {
  color: #ffffff;
}
.login-bar-wrap .login-bar .welcome-name {
  display: inline-block;
  color: #ffffff;
  margin-right: 25px;
  padding-top: 10px;
}
.login-bar-wrap .login-bar .logged-in-actions {
  color: #ffffff;
}
.login-bar-wrap .login-bar .logged-in-actions .btn {
  font-size: 0.9em;
  margin-left: 6px;
}