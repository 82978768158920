@import "./variables.scss";

.ttg-form {
  .form-red {
    color: Red !important;
  }

  label {
    font-weight: 600;
  }

  .action-button {
    cursor: pointer;
    color: $linkColor !important;
    border: none;
    background: none;
    padding: 0;
  }
  .action-button:hover {
    text-decoration: underline !important;
  }

  .geosuggest {
    position: relative;

    .geosuggest__suggests {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      max-height: 25em;
      padding: 0;
      margin-top: -1px;
      background: #fff;
      border: 2px solid $greenColor;
      border-top-width: 0;
      overflow-x: hidden;
      overflow-y: auto;
      list-style: none;
      z-index: 5;
      -webkit-transition: max-height 0.2s, border 0.2s;
      transition: max-height 0.2s, border 0.2s;
    }
    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
    }

    .geosuggest__item {
      font-size: 18px;
      font-size: 1rem;
      padding: 0.5em 0.65em;
      cursor: pointer;
    }
    .geosuggest__item:hover,
    .geosuggest__item:focus {
      background: #f5f5f5;
    }
    .geosuggest__item--active {
      background: $greenColor;
      color: #fff;
    }
    .geosuggest__item--active:hover,
    .geosuggest__item--active:focus {
      background: #ccc;
    }
    .geosuggest__item__matched-text {
      font-weight: bold;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: $fseColor !important;
  }

  .react-datepicker__input-container input {
    padding: 6px 12px;
    box-shadow: 0 0 1px #3d464d;
  }

  .form-check-label {
    font-weight: normal;
    margin-right: 10px;
  }
  .payment-info {
    input {
      padding: 6px 12px;
      box-sizing: content-box;
    }
  }

  .more-info-box {
    border-radius: 25px;
    color: #000000;
    border: 2px solid transparent;
    box-shadow: 0 0 1px #3d464d54;
    padding: 15px;
    background-color: $lightBlueColor;
    margin-bottom: 15px;
    p {
      color: #000000;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  p,
  li {
    color: #000000;
  }

  .mxw-500 {
    max-width: 500px;
    margin-left: 0;
    margin-right: 0;
  }
  .mxw-400 {
    max-width: 400px;
    margin-left: 0;
    margin-right: 0;
  }
  .mxw-300 {
    max-width: 300px;
    margin-left: 0;
    margin-right: 0;
  }
  .mxw-200 {
    max-width: 200px;
    margin-left: 0;
    margin-right: 0;
  }

  // .inline-radio-wrapper {
  //   display: flex;
  //   .inline-radio {
  //     // display: inline-flex;
  //     display: flex;

  //     label {
  //       font-weight: normal;
  //     }
  //   }
  // }
}

@media only screen and (max-width: 768px) {
  .ttg-form {
    .mxw-500,
    .mxw-400,
    .mxw-300,
    .mxw-200 {
      max-width: 100% !important;
    }
  }
}
