.collapsible-panel {
  .header-section {
    cursor: pointer;
  }

  h5 {
    font-weight: normal;
    font-size: 17px;
  }

  div#toggle {
    -webkit-transition: -webkit-transform 1s !important;
    transition: transform 0.5s !important;
  }

  div.toggle-icon {
    max-height: 20px;
  }

  div.toggle-now {
    -webkit-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
  }

  .header-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
