@import "../../../../assets/styles/variables.scss";

.home-hero-wrap {
  background-color: #fcfced;
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/home-hero-bg.jpg");
  margin-top: -102px;
  padding-top: 102px;
  width: 100%;
  overflow: hidden;

  //@media screen and (max-width: 768px) {
  // position: relative;
  // padding-top: 110px;
  // top: -58px;
  //}

  .home-hero {
    max-width: $containerMaxWidth;
    padding: 0 16px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media screen and (max-width: 900px) {
      display: block;
      text-align: center;
      padding-bottom: 50px;
    }

    .home-hero-welcome {
      font-size: 2.25em;
      font-family: Coolvetica;
      font-style: italic;
      letter-spacing: 0.1em;

      font-family: Coolvetica; //font-weight: 700;
      color: $greenColor;
      text-transform: uppercase;

      @media screen and (max-width: 500px) {
        font-size: 2em;
      }
    }

    h1 {
      color: $fseColor;
      font-size: 4.5em;
      font-family: Coolvetica; //font-weight: 700;

      @media screen and (max-width: 500px) {
        font-size: 3.25em;
      }
    }

    h2 {
      font-size: 1.5em;
      color: $fseColor;
      margin-bottom: 25px;
    }

    .home-hero-btn {
      margin-bottom: 50px;
    }

    img {
      margin-right: -100px;
      width: max(100px, 50vw);
      height: auto;

      @media screen and (max-width: 1500px) {
        margin-right: 0;
      }

      @media screen and (max-width: 900px) {
        // display: none;
      }
    }
  }
}
