@import "../../../../assets/styles/variables.scss";

.happy-clients {
  max-width: $containerMaxWidth;
  margin: 0 auto;

  h1 {
    text-align: center;
    font-family: Coolvetica !important; //font-weight: 700;
  }
}
