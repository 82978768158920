.header-image-section {
  height: 30vh;
  min-height: 300px;
  position: relative;
}
.header-image-section .image-container {
  height: 30vh;
  min-height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(60%);
  z-index: 1 !important;
}
.header-image-section .children-container, .header-image-section .text-container {
  z-index: 2 !important;
  width: 100%;
  height: 30vh;
  min-height: 300px;
  position: absolute;
  top: 0;
}