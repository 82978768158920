.avetmiss-validation {
  width: 100%;
  border: 1px solid #cccccc;
  border-collapse: collapse;
  font-size: 0.8em;

  td {
    border: 1px solid #cccccc;
    padding: 5px;
  }
  tr:nth-child(odd) {
    td {
      background-color: #f4f4f4;
    }
  }
  td:nth-of-type(1) {
    width: 150px;
  }
  td:nth-of-type(2) {
    width: 350px;
  }
  td:nth-of-type(2) {
    max-width: 100%;
  }

  textarea {
    font-size: 0.9em !important;
  }
}
