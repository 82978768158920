.content-section-custom-svgs {
  padding: 0;

  .content-section-custom-svgs-top,
  .content-section-custom-svgs-bottom {
    width: 100%;
    position: relative;
    height: 36px; // Controls the height of the custom-svgs section

    .svg-container {
      position: absolute;
      width: 100%;
      height: 100%; // Match the height of the section

      svg {
        width: 100%;
        height: 100%; // Make sure the SVG covers the entire section
      }
    }
  }
}
