.loading-text {
  margin: auto;
  padding: 10px;
}
.loading-text .spinner {
  margin-right: 5px;
}

@media only screen and (max-width: 768px) {
  .content-sync-table {
    border-top: 1px solid #dee2e6;
  }
  .content-sync-table thead {
    display: none;
  }
  .content-sync-table td {
    display: block;
    border-top: none;
  }
  .content-sync-table .status {
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.2em;
  }
  .content-sync-table .action-btns {
    text-align: left;
    border-bottom: 1px solid #dee2e6;
  }
}