@import "../../assets/styles/variables.scss";

.corporate-vouchers-wrapper {
  .voucher-icon {
    display: inline-block;
    color: $orangeColor !important;
    font-size: 1.5em;

    span {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .corporate-vouchers-wrapper {
    .voucher-icon {
      margin: 0 8px 8px 0;
      font-size: 0.9em;
      padding: 0.375rem 0.75rem;
      display: inline-block;
      background-color: $orangeColor;
      border-radius: 25px;
      text-transform: uppercase;
      border: none;
      font-weight: 600;
      color: #ffffff !important;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;

      i {
        display: none;
      }
      span {
        display: inline;
      }
    }
  }
}
