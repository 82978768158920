.home-hero-wrap {
  background-color: #fcfced;
  background-image: url("https://solvable.azureedge.net/train-to-gain/images/home-hero-bg.jpg");
  margin-top: -102px;
  padding-top: 102px;
  width: 100%;
  overflow: hidden;
}
.home-hero-wrap .home-hero {
  max-width: 1340px;
  padding: 0 16px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .home-hero-wrap .home-hero {
    display: block;
    text-align: center;
    padding-bottom: 50px;
  }
}
.home-hero-wrap .home-hero .home-hero-welcome {
  font-size: 2.25em;
  font-family: Coolvetica;
  font-style: italic;
  letter-spacing: 0.1em;
  font-family: Coolvetica;
  color: #50a72c;
  text-transform: uppercase;
}
@media screen and (max-width: 500px) {
  .home-hero-wrap .home-hero .home-hero-welcome {
    font-size: 2em;
  }
}
.home-hero-wrap .home-hero h1 {
  color: #064f7a;
  font-size: 4.5em;
  font-family: Coolvetica;
}
@media screen and (max-width: 500px) {
  .home-hero-wrap .home-hero h1 {
    font-size: 3.25em;
  }
}
.home-hero-wrap .home-hero h2 {
  font-size: 1.5em;
  color: #064f7a;
  margin-bottom: 25px;
}
.home-hero-wrap .home-hero .home-hero-btn {
  margin-bottom: 50px;
}
.home-hero-wrap .home-hero img {
  margin-right: -100px;
  width: max(100px, 50vw);
  height: auto;
}
@media screen and (max-width: 1500px) {
  .home-hero-wrap .home-hero img {
    margin-right: 0;
  }
}