.select-a-course {
  max-width: 1340px;
  margin: 0 auto;
}
.select-a-course h1 {
  text-align: center;
  font-family: Coolvetica !important;
}
.select-a-course .help-question-text {
  font-size: 1.25em;
  font-weight: 600;
  color: #064f7a;
  margin: 16px 0;
}
.select-a-course .course-card {
  border-radius: 16px;
  height: 100%;
  transition: all 0.2s ease-in-out;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
}
.select-a-course .course-card:hover {
  border: 1px solid #50a72c;
  box-shadow: 3px -1px 25px 0px rgba(76, 146, 48, 0.4);
}
.select-a-course .course-card img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.select-a-course .course-card .card-title {
  font-size: 1.25em;
  font-weight: 600;
  color: #064f7a;
  text-align: left;
}
.select-a-course .course-card .card-text {
  font-size: 1.1em;
  color: #064f7a;
  text-align: left;
}
.select-a-course .course-card .card-footer {
  background-color: unset !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select-a-course .course-card .card-footer .course-cost {
  color: #064f7a;
  font-size: 1.75em;
  font-weight: 400;
  font-family: Coolvetica;
  letter-spacing: 0.05em;
}