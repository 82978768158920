footer {
  background: #014b6f;
  color: #ffffff;
  max-width: 100%;
  font-size: 0.9em;
}
footer .row {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  padding-bottom: 20px;
}
footer .row img {
  width: 320px;
  margin-bottom: 16px;
}
footer .row .footer-heading {
  color: #ffbd3c;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 12px;
}
footer .row ul {
  padding-inline-start: 0;
}
footer .row ul li {
  list-style-type: none;
  margin-bottom: 12px;
}
footer .row ul li a {
  color: #ffffff;
  text-transform: uppercase;
}
footer .row ul li a span {
  text-transform: capitalize;
}