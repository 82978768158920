.customer-testimonials {
  .customer-testimonial {
    color: #fff;
    text-align: center;
    font-size: 1.5em;

    .customer-name {
      font-size: 0.8em;
      font-weight: 600;
      margin-top: 0.5em;

      &::before {
        content: "–";
        margin-right: 0.35em;
      }
    }
  }

  .owl-dots {
    margin-top: 1.25em !important;
  }
}
