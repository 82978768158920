@import "./assets/styles/variables.scss";
@import "./assets/styles/core/content/content";
@import "./assets/styles/core/backgrounds/backgrounds";

html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  min-height: 100vh;
  position: relative;
}

.ttg-app {
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

h1,
.heading-text {
  font-family: Coolvetica;
  letter-spacing: 0.05em;
}

.ttg-app img {
  max-width: 100%;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.flex-100 {
  flex: 1;
}

.ttg-container {
  max-width: $containerMaxWidth;
  margin-left: auto;
  margin-right: auto;

  h1 {
    font-family: Coolvetica; //font-weight: 700;
  }
}

.ttg-btn,
.ttg-blue-btn,
.ttg-red-btn,
.ttg-arrow-btn,
.ttg-blue-green-btn {
  display: inline-block;
  background-color: $orangeColor;
  border-radius: 25px;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.ttg-btn {
  padding: 0.375rem 0.75rem;
}

.ttg-lg-btn {
  padding: 0.75rem 1.25rem;
}

.ttg-arrow-btn {
  padding: 0.75rem 0.75rem;
}
.ttg-blue-btn {
  background-color: $fseColor;
}
.ttg-red-btn {
  background-color: $redColor;
}
.ttg-blue-green-btn {
  background-color: $blueGreenColor;
}

.ttg-orange-btn {
  background-color: $orangeColor;
}
.ttg-light-orange-btn {
  background-color: $lightOrangeColor;
}

.ttg-orange-outline-btn {
  background-color: #fff;
  border: 2px solid $orangeColor;
  color: $orangeColor;
}
.ttg-green-outline-btn {
  background-color: #fff;
  border: 2px solid $greenColor;
  color: $greenColor;
}
.ttg-blue-outline-btn {
  background-color: #fff;
  border: 2px solid $fseColor;
  color: $fseColor;
}

.ttg-btn:hover,
.ttg-arrow-btn:hover,
.ttg-orange-btn:hover,
.ttg-light-orange-btn:hover {
  background-color: $fseColor;
  text-decoration: none;
  color: #ffffff;
}

.ttg-blue-btn:hover {
  background-color: $orangeColor;
  color: #ffffff;
}

.ttg-red-btn:hover {
  background-color: $orangeColor;
  color: #ffffff;
}

.ttg-blue-green-btn:hover {
  background-color: $orangeColor;
  color: #ffffff;
}

.ttg-orange-outline-btn:hover {
  background-color: $orangeColor;
  color: #ffffff;
}
.ttg-green-outline-btn:hover {
  background-color: $greenColor;
  color: #ffffff;
}
.ttg-blue-outline-btn:hover {
  background-color: $fseColor;
  color: #ffffff;
}

.sml-ttg-btn {
  font-size: 0.8em;
}

.mxw-400 {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.ttg-box-shadow {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
}

.Toastify__toast--success {
  background-color: $fseColor !important;
}

.progress {
  background-color: $fseColor;
  height: 1.5rem;
}
.progress-bar {
  background-color: $orangeColor;
}

.no-last-name {
  text-decoration: underline !important;
  color: $orangeColor !important;
  cursor: pointer !important;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.standards-compliant-tick {
  width: 120px;
}

.ttg-form {
  input,
  select,
  textarea {
    border-radius: 25px;
    color: $paragraphColor;
    border: 2px solid transparent;
    box-shadow: 0 0 1px #3d464d54;
    -webkit-transition: border 0.2s, box-shadow 0.2s;
    transition: border 0.2s, box-shadow 0.2s;
  }
  input:focus,
  select:focus,
  textarea:focus {
    border-color: $greenColor;
    box-shadow: 0 0 0 transparent;
  }
  input[type="radio"],
  input[type="checkbox"] {
    box-shadow: 0 0 0 transparent;
  }
}

.course-content {
  table {
    border: 1px solid #dee2e6;
  }
  table th,
  table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border: 1px solid #dee2e6;
  }
  table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    border-bottom-width: 2px;
  }
  table thead td {
    border-bottom-width: 2px;
  }
  table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
}

// @media only screen and (max-width: 576px) {
//   .ttg-app {
//     padding-top: 160px;
//   }

//   // h1 {
//   //   font-size: 20px !important;
//   // }

//   .ttg-app-pv {
//     padding-top: 123px;
//   }
// }

// @media only screen and (max-width: 400px) {
//   .ttg-app {
//     padding-top: 138px;
//   }

//   // h1 {
//   //   font-size: 20px !important;
//   // }

//   .ttg-app-pv {
//     padding-top: 101px;
//   }
// }

// @media only screen and (max-width: 321px) {
//   .ttg-app {
//     padding-top: 123px;
//   }

//   // h1 {
//   //   font-size: 20px !important;
//   // }

//   .ttg-app-pv {
//     padding-top: 86px;
//   }
// }
