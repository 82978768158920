@charset "UTF-8";
.customer-testimonials .customer-testimonial {
  color: #fff;
  text-align: center;
  font-size: 1.5em;
}
.customer-testimonials .customer-testimonial .customer-name {
  font-size: 0.8em;
  font-weight: 600;
  margin-top: 0.5em;
}
.customer-testimonials .customer-testimonial .customer-name::before {
  content: "–";
  margin-right: 0.35em;
}
.customer-testimonials .owl-dots {
  margin-top: 1.25em !important;
}