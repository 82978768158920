.owl-stage-outer {
  padding: 10px;
}

.owl-carousel .owl-stage {
  display: flex;
}

.owl-dots .owl-dot span {
  background-color: #bfdeb0 !important;
}

.owl-dots .active span {
  background-color: #50a72c !important;
}