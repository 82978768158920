.fileUploader {
  .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //margin-top: 16px;
  }

  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    // max-height: 250px;
    //width: 200px;
    padding: 4px;
    box-sizing: border-box;
  }

  .thumb-img {
    max-height: 150px !important;
  }

  .remove-button {
    position: absolute;
    left: 85%;
    bottom: 85%;
  }

  .mini-thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: auto;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
  }

  .thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
  }

  .fileDetails {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  // Obsolete
  .imgx {
    display: block;
    width: auto;
    height: 100%;
  }

  .drag-msgx {
    pointer-events: none !important;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }

  .pdf {
    color: #df2745 !important;
  }

  .word {
    color: #00a8ec !important;
  }

  .excel {
    color: #127f46 !important;
  }

  .file {
    color: #a7a7a7 !important;
  }

  .image {
    color: #9981bc !important;
  }
}

// .item-enter {
//   opacity: 0;
// }
// .item-enter-active {
//   opacity: 1;
//   transition: opacity 500ms ease-in;
// }
// .item-exit {
//   opacity: 1;
// }
// .item-exit-active {
//   opacity: 0;
//   transition: opacity 500ms ease-in;
// }
