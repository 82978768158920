$containerMaxWidth: 1340px;

$fseColor: #064f7a;
$orangeColor: #fc7214;
$greenColor: #50a72c;
$lightGreenColor: #bfdeb0;
$headingFontFamily: "Open Sans"; // "Coolvetica";
$heading1FontWeight: "700";
$paragraphColor: #000000;
$linkColor: #007bff;
$lightBlueColor: #daf1ff;
$redColor: #ff3548;
$blueGreenColor: #91ddcf;
$lightOrangeColor: #ffbd3c;

$siteSecondaryColor: $greenColor;
$navBarBgColor: $fseColor;

// ===============================
// Solvable Priority Nav variables
// ===============================
$navPadding: 8px;
$navItemPadding: 10px;
$navLogoMaxHeight: 82px;
$navFontSize: 1em;
$navColor: $navBarBgColor;
$navTextColor: $fseColor;
$navTextHoverColor: $orangeColor;
$navBarActiveLinkColor: $orangeColor;
$navBtnColor: $navTextColor;
$navBtnHoverColor: $navTextHoverColor;
$navDropdownRightOffset: 0;
