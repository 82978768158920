.certificate-info {
  max-width: min(1250px, 100vw);
  margin: -100px auto 50px auto;
  background-color: #fff;
  padding: 50px 64px;
  box-shadow: -2px 10px 32px 0px rgba(12, 86, 105, 0.1);
}
@media screen and (max-width: 768px) {
  .certificate-info {
    padding: 32px;
  }
}
.certificate-info h1 {
  font-family: Coolvetica !important;
  font-size: 1.5em !important;
  color: #064f7a;
  margin-bottom: 16px;
}
.certificate-info .hr-line {
  width: 100%;
  height: 10px;
  background-color: #fc7214;
  margin-bottom: 32px;
}
.certificate-info .hc-line {
  width: 100%;
  height: 10px;
  background-color: #50a72c;
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .certificate-info .hc-line {
    margin-top: 32px;
  }
}
.certificate-info .certificate-details {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 2px solid #dedede;
}
.certificate-info .certificate-details:first-of-type {
  border-top: none;
}
.certificate-info .certificate-details .certificate-details-icon {
  width: 90px;
  min-width: 90px;
  height: 90px;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}
.certificate-info .certificate-details .certificate-details-icon img {
  max-height: 90px;
}
.certificate-info .certificate-details .orange-icon {
  background-color: #fc7214;
}
.certificate-info .certificate-details .green-icon {
  background-color: #50a72c;
}
.certificate-info .certificate-details h2 {
  color: #50a72c;
  font-size: 1.25em;
  margin-bottom: 8px;
}
.certificate-info .certificate-details h3 {
  font-size: 1em;
  color: #000;
}