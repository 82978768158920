#signaturePad .signature-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
}
#signaturePad .signature-toolBox {
  background-color: #e8e8e8;
}
#signaturePad .signature-box-item {
  border-radius: 2px;
  border: 1px solid #eaeaea;
}