@import "../../assets/styles/variables.scss";

.your-courses-table {
  color: #000000;
  min-height: 450px;
  height: 450px;

  .course-heading {
  }

  .date-heading {
    width: 120px;
  }

  .status-heading {
    width: 160px;
  }

  .action-btns {
    .btn {
      font-size: 0.9em;
      margin: 5px 5px 5px 0;
    }
  }

  .course-name {
    font-weight: 600;
  }
}

@media only screen and (max-width: 768px) {
  .your-courses-table {
    border-top: 1px solid #dee2e6;
    thead {
      display: none;
    }

    td {
      display: block;
      border-top: none;
    }
    .course-val:before {
      content: "Course";
      font-weight: 600;
      display: block;
    }
    .date-val:before {
      content: "Enrolled On";
      font-weight: 600;
      display: block;
    }
    .status-val:before {
      content: "Status";
      font-weight: 600;
      display: block;
    }

    .status-val {
      border-bottom: 1px solid #dee2e6;
    }
  }
}
