.avetmiss-validation {
  width: 100%;
  border: 1px solid #cccccc;
  border-collapse: collapse;
  font-size: 0.8em;
}
.avetmiss-validation td {
  border: 1px solid #cccccc;
  padding: 5px;
}
.avetmiss-validation tr:nth-child(odd) td {
  background-color: #f4f4f4;
}
.avetmiss-validation td:nth-of-type(1) {
  width: 150px;
}
.avetmiss-validation td:nth-of-type(2) {
  width: 350px;
}
.avetmiss-validation td:nth-of-type(2) {
  max-width: 100%;
}
.avetmiss-validation textarea {
  font-size: 0.9em !important;
}