@import "../../assets/styles/variables.scss";

.certificate-register {
  max-width: $containerMaxWidth;
  margin: 0 auto;

  h1 {
    font-family: Coolvetica; //font-weight: 700;
  }
}
