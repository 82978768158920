.fileUploader .thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.fileUploader .thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 4px;
  box-sizing: border-box;
}
.fileUploader .thumb-img {
  max-height: 150px !important;
}
.fileUploader .remove-button {
  position: absolute;
  left: 85%;
  bottom: 85%;
}
.fileUploader .mini-thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: auto;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}
.fileUploader .thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}
.fileUploader .fileDetails {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.fileUploader .imgx {
  display: block;
  width: auto;
  height: 100%;
}
.fileUploader .drag-msgx {
  pointer-events: none !important;
}
.fileUploader .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.fileUploader .pdf {
  color: #df2745 !important;
}
.fileUploader .word {
  color: #00a8ec !important;
}
.fileUploader .excel {
  color: #127f46 !important;
}
.fileUploader .file {
  color: #a7a7a7 !important;
}
.fileUploader .image {
  color: #9981bc !important;
}