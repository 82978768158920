$fieldErrorColor: #e82d2d !important;

.loqate-field-address {
  .hide-address {
    display: none;
  }
}

.loqate-address {
  div[class$="-control"] {
    border-radius: 25px;
    color: #000000;
    border: 2px solid transparent;
    box-shadow: 0 0 1px rgb(61 70 77 / 33%);
    transition: border 0.2s, box-shadow 0.2s;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    cursor: text;

    div[class$="-Input"] {
      input {
        box-shadow: none;
      }
    }

    &:focus-within {
      border-color: #5eac39;
    }

    &.field-error {
      div[class$="-control"] {
        border-color: $fieldErrorColor;
      }
    }
  }
}
