.quiz-alert {
  color: #9e0606;
  font-weight: 600;
}

.course-quiz-container {
  max-width: 1340px;
  margin: 0 auto;
  background-color: #fff;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.course-quiz-container .quiz-loading-content {
  min-height: 400px;
}