.course-content-sections {
  margin: 15px 0;
}
.course-content-sections .section {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #fc7214;
  border-radius: 50%;
  margin-right: 8px;
  line-height: 40px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 1.5em;
  color: #ffffff;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: default;
}
.course-content-sections .current {
  background-color: #50a72c;
}