.content-section-intersecting-waves {
  padding: 0;

  .content-section-intersecting-waves-top,
  .content-section-intersecting-waves-bottom {
    width: 100%;
    position: relative;
    height: 50px; // Controls the height of the intersecting-waves section

    .svg-container {
      position: absolute;
      width: 100%;
      height: 100%; // Match the height of the section

      svg {
        width: 100%;
        height: 100%; // Make sure the SVG covers the entire section
      }
    }
  }
}
