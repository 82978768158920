.third-party-form-info {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
}
.third-party-form-info td {
  border: 1px solid #dee2e6;
  padding: 5px;
}
.third-party-form-info tr td:first-of-type {
  font-weight: 600;
  min-width: 160px;
  background-color: #f2f1ee;
}
.third-party-form-info .unit-of-competency {
  display: block;
}
.third-party-form-info .no-bg td {
  background-color: unset !important;
}

.third-party-form-sample::before {
  position: absolute;
  width: 100%;
  height: 2000px;
  background-image: url("/userfiles/images/sample-bg.png");
  background-repeat: repeat;
  background-position: top center;
  z-index: 20;
  opacity: 0.2;
  content: "";
}

.third-party-tasks {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
}
.third-party-tasks thead th {
  background-color: #e3e2e0;
  font-weight: 700;
}
.third-party-tasks th {
  font-weight: 600;
  background-color: #e8e7e5;
}
.third-party-tasks td {
  vertical-align: middle;
}
.third-party-tasks td:first-of-type {
  width: 65%;
}
.third-party-tasks td:nth-of-type(2) {
  width: 120px;
}

@media only screen and (max-width: 1023px) {
  .third-party-form-info {
    border: none;
  }
  .third-party-form-info tr,
  .third-party-form-info td {
    display: block;
    border-top: none;
  }
  .third-party-form-info tr:first-of-type {
    border-top: 1px solid #dee2e6;
  }
  .third-party-tasks {
    border: none;
  }
  .third-party-tasks tr,
  .third-party-tasks th,
  .third-party-tasks td {
    display: block;
    border-top: none;
    width: 100% !important;
    text-align: center;
  }
  .third-party-tasks tr .form-check,
  .third-party-tasks th .form-check,
  .third-party-tasks td .form-check {
    font-size: 1.2em;
  }
  .third-party-tasks tr .form-check label,
  .third-party-tasks th .form-check label,
  .third-party-tasks td .form-check label {
    margin-right: 0 !important;
  }
  .third-party-tasks tr .form-check label:first-of-type,
  .third-party-tasks th .form-check label:first-of-type,
  .third-party-tasks td .form-check label:first-of-type {
    margin-right: 20px !important;
  }
  td:first-of-type {
    font-weight: 500;
    font-size: 1.1em;
  }
  td:nth-of-type(2) {
    font-size: 1.1em;
  }
}
@media only screen and (max-width: 900px) {
  .third-party-form-sample::before {
    background-size: contain;
  }
}