.header-image-section {
  height: 30vh;
  min-height: 300px;
  position: relative;

  .image-container {
    height: 30vh;
    min-height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(60%);
    z-index: 1 !important;
  }

  .children-container {
    z-index: 2 !important;
    width: 100%;
    height: 30vh;
    min-height: 300px;
    position: absolute;
    top: 0;
  }

  .text-container {
    @extend .children-container;
  }
}
