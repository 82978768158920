.business-training {
  max-width: 1340px;
  margin: 0 auto;
}
.business-training .heading-text {
  font-size: 2em;
  font-family: Coolvetica;
  color: #064f7a;
}
@media screen and (max-width: 500px) {
  .business-training .heading-text {
    font-size: 1.75em;
  }
}
.business-training h1 {
  font-family: Coolvetica;
}
.business-training p {
  font-size: 1.25em;
}