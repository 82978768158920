.your-courses-table {
  color: #000000;
  min-height: 450px;
  height: 450px;
}
.your-courses-table .date-heading {
  width: 120px;
}
.your-courses-table .status-heading {
  width: 160px;
}
.your-courses-table .action-btns .btn {
  font-size: 0.9em;
  margin: 5px 5px 5px 0;
}
.your-courses-table .course-name {
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .your-courses-table {
    border-top: 1px solid #dee2e6;
  }
  .your-courses-table thead {
    display: none;
  }
  .your-courses-table td {
    display: block;
    border-top: none;
  }
  .your-courses-table .course-val:before {
    content: "Course";
    font-weight: 600;
    display: block;
  }
  .your-courses-table .date-val:before {
    content: "Enrolled On";
    font-weight: 600;
    display: block;
  }
  .your-courses-table .status-val:before {
    content: "Status";
    font-weight: 600;
    display: block;
  }
  .your-courses-table .status-val {
    border-bottom: 1px solid #dee2e6;
  }
}