@import "../../assets/styles/variables.scss";

.certificates {
  max-width: $containerMaxWidth;
  margin: 0 auto;
}

.certificates-why-train-with-us {
  max-width: $containerMaxWidth;
  margin: 0 auto;

  h1 {
    text-align: center;
    font-family: Coolvetica; //font-weight: 700;
  }

  .small-green-tick-balls {
    .green-tick-balls-item {
      .green-tick-balls-item-text {
        color: $fseColor;
      }
    }
  }
}
