.third-party-form-info {
  border: 1px solid #dee2e6;
  border-collapse: collapse;

  td {
    border: 1px solid #dee2e6;
    padding: 5px;
  }

  tr td:first-of-type {
    font-weight: 600;
    min-width: 160px;
    background-color: #f2f1ee;
  }

  .unit-of-competency {
    display: block;
  }

  .no-bg td {
    background-color: unset !important;
  }
}

.third-party-form-sample::before {
  position: absolute;
  width: 100%;
  height: 2000px;
  background-image: url("/userfiles/images/sample-bg.png");
  background-repeat: repeat;
  background-position: top center;
  z-index: 20;
  opacity: 0.2;
  content: "";
}

.third-party-tasks {
  border: 1px solid #dee2e6;
  border-collapse: collapse;

  thead {
    th {
      background-color: #e3e2e0;
      font-weight: 700;
    }
  }

  th {
    font-weight: 600;
    background-color: #e8e7e5;
  }

  td {
    vertical-align: middle;
  }

  td:first-of-type {
    width: 65%;
  }
  td:nth-of-type(2) {
    width: 120px;
  }
}

@media only screen and (max-width: 1023px) {
  .third-party-form-info {
    border: none;

    tr,
    td {
      display: block;
      border-top: none;
    }
    tr:first-of-type {
      border-top: 1px solid #dee2e6;
    }
  }

  .third-party-tasks {
    border: none;

    tr,
    th,
    td {
      display: block;
      border-top: none;
      width: 100% !important;

      text-align: center;

      .form-check {
        font-size: 1.2em;

        label {
          margin-right: 0 !important;
        }
        label:first-of-type {
          margin-right: 20px !important;
        }
      }
    }
  }

  td:first-of-type {
    font-weight: 500;
    font-size: 1.1em;
  }

  td:nth-of-type(2) {
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 900px) {
  .third-party-form-sample::before {
    background-size: contain;
  }
}
