.owl-item {
  height: 60px;
}

.home-testimonial {
  height: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 50px;
}
