.green-tick-balls, .small-green-tick-balls {
  display: flex;
  flex-direction: column;
}
.green-tick-balls .green-tick-balls-item, .small-green-tick-balls .green-tick-balls-item {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.green-tick-balls .green-tick-balls-item .green-tick-balls-item-icon, .small-green-tick-balls .green-tick-balls-item .green-tick-balls-item-icon {
  background-color: #50a72c;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
}
.green-tick-balls .green-tick-balls-item .green-tick-balls-item-icon img, .small-green-tick-balls .green-tick-balls-item .green-tick-balls-item-icon img {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.small-green-tick-balls .green-tick-balls-item .green-tick-balls-item-icon {
  width: 30px;
  height: 30px;
}
.small-green-tick-balls .green-tick-balls-item .green-tick-balls-item-icon img {
  width: 30px;
  min-width: 30px;
  height: 30px;
}