.certificates {
  max-width: 1340px;
  margin: 0 auto;
}

.certificates-why-train-with-us {
  max-width: 1340px;
  margin: 0 auto;
}
.certificates-why-train-with-us h1 {
  text-align: center;
  font-family: Coolvetica;
}
.certificates-why-train-with-us .small-green-tick-balls .green-tick-balls-item .green-tick-balls-item-text {
  color: #064f7a;
}