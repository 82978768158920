@import "../../../../assets/styles/variables.scss";

.whats-next {
  position: relative;
  padding-left: 512px;
  max-width: 1024px;
  margin: 25px auto 0 auto;
  min-height: 600px;

  @media screen and (max-width: 1150px) {
    min-height: 500px;
  }

  @media screen and (max-width: 900px) {
    padding-left: 16px;
    max-width: 90vw;
    text-align: center;
  }

  display: flex;

  flex-direction: column;
  justify-content: center;

  .heading-text {
    font-size: 2em;
    font-family: Coolvetica; //font-weight: 700;
    color: $fseColor;
  }

  .sub-heading-text {
    font-size: 1.15em;
    margin-bottom: 25px;
  }

  .whats-next-text {
    font-size: 1.5em;
    color: $fseColor;
    margin-bottom: 25px;
  }

  .whats-next-image {
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1150px) {
      left: 80px;
    }

    @media screen and (max-width: 900px) {
      top: auto;
      left: auto;
      position: relative;
    }

    img {
      width: 480px;
      z-index: 1;

      @media screen and (max-width: 1150px) {
        width: 400px;
      }
    }

    .whats-next-image-overlay {
      background-color: $orangeColor;
      color: #fff;
      display: flex;

      width: 350px;
      margin-left: -60px;
      margin-top: -90px;
      z-index: 2;
      position: relative;
      padding: 8px 16px;

      align-items: center;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;

      @media screen and (max-width: 900px) {
        margin-left: calc(50% - 225px);
        margin-right: auto;
      }

      @media screen and (max-width: 500px) {
        margin-left: calc(50% - 195px);
        margin-right: auto;
      }

      .whats-next-image-overlay-text-left {
        font-size: 2.5em;
        margin: 0 12px 0 0;
      }

      .whats-next-image-overlay-text-right {
        font-size: 1em;
        margin: 0;
      }
    }
  }

  .small-green-tick-balls {
    margin-bottom: 25px;

    @media screen and (max-width: 900px) {
      width: min(380px, 85vw);
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }

    .green-tick-balls-item {
      .green-tick-balls-item-text {
        font-size: 1.15em;
        color: $fseColor;
        font-weight: 600;
      }
    }
  }

  .ttg-btn {
    width: 150px;
    text-align: center;
    margin-bottom: 20px;

    @media screen and (max-width: 900px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
