.infographic {
  background-color: #ecaf38;
  box-shadow: -2px 10px 22px 0px rgba(0, 0, 0, 0.17);
  max-width: min(1550px, 100vw);
  margin: -60px auto 0 auto;
  display: flex;
  justify-content: space-evenly;
  padding: 25px 0;
}
@media screen and (max-width: 1300px) {
  .infographic {
    margin-top: -30px;
  }
}
@media screen and (max-width: 900px) {
  .infographic {
    margin-top: -50px;
    flex-direction: column;
    max-width: min(500px, 80vw);
    padding: 13px 0;
  }
}
.infographic .infographic-item {
  width: 20%;
  padding: 0 32px;
  display: flex;
  align-items: center;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 1550px) {
  .infographic .infographic-item {
    padding: 0 20px;
  }
}
@media screen and (max-width: 900px) {
  .infographic .infographic-item {
    width: 100%;
    border-left: none;
  }
}
.infographic .infographic-item img {
  height: 70px;
  margin-right: 16px;
}
@media screen and (max-width: 1550px) {
  .infographic .infographic-item img {
    height: 60px;
  }
}
@media screen and (max-width: 1300px) {
  .infographic .infographic-item img {
    height: 50px;
  }
}
@media screen and (max-width: 1000px) {
  .infographic .infographic-item img {
    height: 40px;
  }
}
@media screen and (max-width: 900px) {
  .infographic .infographic-item img {
    width: 90px;
    min-width: 90px;
    height: 70px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.infographic .infographic-item span {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 100%;
}
@media screen and (max-width: 1550px) {
  .infographic .infographic-item span {
    font-size: max(12px, 0.9vw);
  }
}
@media screen and (max-width: 900px) {
  .infographic .infographic-item span {
    font-size: 1.1em;
  }
}
.infographic .infographic-item:first-child {
  border-left: none;
}