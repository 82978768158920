@import "../../../../assets/styles/variables.scss";

.why-train-with-us {
  max-width: $containerMaxWidth;
  margin: 0 auto;
  padding-bottom: 100px;
  @media screen and (max-width: 500px) {
    padding-bottom: 120px;
  }

  h1 {
    text-align: center;
    font-family: Coolvetica !important;

    @media screen and (max-width: 500px) {
      font-size: 1.75em !important;
    }
  }

  .small-green-tick-balls {
    .green-tick-balls-item {
      .green-tick-balls-item-text {
        color: $fseColor;
      }
    }
  }
}
