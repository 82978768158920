@import "../../assets/styles/variables.scss";

@mixin navItemLinkStyle($textColor, $textHoverColor) {
  text-decoration: none;
  color: $textColor;
  cursor: pointer;

  &:hover {
    color: $textHoverColor;
  }
}

@mixin navItemButtonStyle($textColor, $textHoverColor) {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: content-box;
  @include navItemLinkStyle($textColor, $textHoverColor);
}

.priority-nav {
  display: flex;
  align-items: stretch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: $navFontSize;
  position: relative;
  padding: $navPadding $navPadding $navPadding calc(#{$navPadding} * 2);

  .priority-nav-logo {
    display: block;
    margin-right: $navPadding;
    max-height: $navLogoMaxHeight;
    flex-shrink: 0;
  }

  .priority-nav-bar-wrap {
    flex: 1;
    overflow: hidden;

    .priority-nav-bar {
      height: 100%;
      position: relative;
      white-space: nowrap;
      text-align: right;
      color: $navTextColor;

      .priority-nav-items-mt {
        margin-top: calc(
          (#{$navLogoMaxHeight} - #{$navFontSize} - #{$navItemPadding}) / 4
        );
      }

      .priority-nav-items {
        display: inline-block;

        .priority-nav-item {
          display: inline-block;
          padding: $navItemPadding;

          a {
            line-height: 1.5em;
            @include navItemLinkStyle($navTextColor, $navTextHoverColor);
          }

          button {
            @include navItemButtonStyle($navTextColor, $navTextHoverColor);
          }
        }

        .priority-nav-btn {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          padding: 0 $navPadding;
          outline: none;
          -webkit-tap-highlight-color: transparent;
          color: $navBtnColor;

          svg {
            vertical-align: top !important;
            margin-top: 1px;
          }

          :hover {
            color: $navBtnHoverColor;
          }
        }

        .priority-nav-btn:only-child {
          margin-top: calc(
            (#{$navLogoMaxHeight} - #{$navFontSize} - #{$navItemPadding}) / 3
          );
        }
      }
    }
  }

  .priority-nav-menu {
    background-color: $navColor;
    color: $navTextColor;
    display: inline-flex;
    position: absolute;
    right: $navDropdownRightOffset;
    margin-top: $navPadding;
    z-index: 1000;
    padding: 0 0 calc(#{$navPadding} * 2) 0;

    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-inline-start: 0;
      padding-left: 0;
      list-style-type: none;

      li {
        text-align: left;
        padding: $navPadding calc(#{$navPadding} * 4);

        a {
          @include navItemLinkStyle($navTextColor, $navBtnHoverColor);
        }

        button {
          @include navItemButtonStyle($navTextColor, $navBtnHoverColor);
        }
      }
    }
  }
}

.header-top {
}

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1000; // Ensure it stays on top of other elements
}
