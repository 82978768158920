@import "../../../../assets/styles/variables.scss";

.business-info {
  max-width: $containerMaxWidth;
  margin: 0 auto;

  h1 {
    font-family: Coolvetica !important; //font-weight: 700;
  }

  h2 {
    color: $greenColor !important;
  }
}
