/**
* Look and feel of field components
*/
.field-error {
  border-color: #e82d2d !important;
  box-shadow: 0 0 0 transparent !important;
  display: block !important;
}

.react-date-full {
  display: block !important;
}

.field-error-react-select {
  border: solid red 2px;
  border-radius: 5px;
}

.inline-block {
  display: inline-block;
}

.generic-error-border, .radio-list, .field-error-checkbox {
  border-radius: 25px;
  border-color: #e82d2d !important;
  border: 2px solid transparent;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
}

.field-error-checkbox {
  padding: 5px;
}

.radio-list {
  padding: 5px 5px 5px 14px;
}