@import "../../../../assets/styles/variables.scss";

.certificate-info {
  max-width: min(1250px, 100vw);
  margin: -100px auto 50px auto;

  background-color: #fff;
  padding: 50px 64px;
  box-shadow: -2px 10px 32px 0px rgba(12, 86, 105, 0.1);

  @media screen and (max-width: 768px) {
    padding: 32px;
  }

  h1 {
    font-family: Coolvetica !important;
    font-size: 1.5em !important;
    color: $fseColor;
    margin-bottom: 16px;
  }

  .hr-line {
    width: 100%;
    height: 10px;
    background-color: $orangeColor;
    margin-bottom: 32px;
  }

  .hc-line {
    width: 100%;
    height: 10px;
    background-color: $greenColor;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      margin-top: 32px;
    }
  }

  .certificate-details {
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 2px solid #dedede;

    &:first-of-type {
      border-top: none;
    }

    .certificate-details-icon {
      width: 90px;
      min-width: 90px;
      height: 90px;
      padding: 16px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;

      img {
        max-height: 90px;
      }
    }

    .orange-icon {
      background-color: $orangeColor;
    }

    .green-icon {
      background-color: $greenColor;
    }

    h2 {
      color: $greenColor;
      font-size: 1.25em;
      margin-bottom: 8px;
    }

    h3 {
      font-size: 1em;
      color: #000;
    }
  }
}
