.update-profile {
  @media (min-width: 0px) {
    .last-name {
      margin-top: 15px;
    }
  }

  @media (min-width: 576px) {
    .last-name {
      margin-top: 0px;
    }
  }
}
