@import "../../../assets//styles/variables.scss";

.action-buttons {
  display: inline-block;
  font-size: 1.2em;

  .orange {
    color: $orangeColor !important;
    margin-right: 3px;
  }

  // General button color
  .g-button-color {
    color: $orangeColor !important;
    background-color: transparent;
    margin-right: 3px;
  }

  .btn-secondary {
    border-color: #bbc3ca !important;
  }
}
