@import "../../assets/styles/variables.scss";

.course-info {
  .course-info-content {
    max-width: $containerMaxWidth;
    margin: 0 auto;

    h1 {
      font-family: Coolvetica; //font-weight: 700;
    }

    h2 {
      font-size: 1.3em;
    }

    h3 {
      font-size: 1.25em;
      font-weight: 400;
      line-height: 1.5em;
      color: #000;
    }

    h5 {
      color: #000;
    }

    .course-price {
      font-size: 2em;
      margin: 16px 0;
      font-weight: 400;
      font-family: Coolvetica;
      color: $fseColor;
      letter-spacing: 0.05em;
    }

    .course-img {
      max-height: 470px;
      border: 1px solid $greenColor;
      box-shadow: 3px -1px 25px 0px rgba(76, 146, 48, 0.4);
      border-radius: 16px;
    }

    .card-body {
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .infographic {
    margin-top: 25px !important;
  }
}
